import styled from "styled-components"

import { getSensorValue } from "src/data/devices/logic/deviceLogic"
import { TChargeStatus, TDevice } from "src/data/devices/types/deviceTypes"
import { TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { TTemperatureUnit } from "src/data/user/user"
import { colorsV2 } from "src/ui/colors"
import AlmostFreezingIcon from "src/ui/icons/eventIcons/event-freezing.svg"
import NormalHumidityIcon from "src/ui/icons/humidity.svg"
import HighHumidityIcon from "src/ui/icons/humidity-high.svg"
import LowHumidityIcon from "src/ui/icons/humidity-low.svg"
import NormalTemperatureIcon from "src/ui/icons/temperature.svg"
import HighTemperatureIcon from "src/ui/icons/temperature-high.svg"
import LowTemperatureIcon from "src/ui/icons/temperature-low.svg"
import LocalizedTemperature from "src/ui/LocalizedTemperature"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { convertTemperatureValue } from "src/utils/l10n"

export function IndoorClimateStatus({
  device,
  indoorClimateMonitoring,
}: {
  device: TDevice
  indoorClimateMonitoring?: TIndoorClimateMonitoring
}) {
  const user = useGetUser()
  const unit = getTemperatureUnitWithFallback(user)

  const chargeStatus = device.charge_status
  const sensorValues = device.latest_sensor_values
  const ongoingIndoorClimateAlerts = device.ongoing_indoor_climate_alerts || []

  return (
    <Box>
      <TemperatureReaction
        sensorValues={sensorValues}
        unit={unit}
        chargeStatus={chargeStatus}
        indoorClimateMonitoring={indoorClimateMonitoring}
        ongoingIndoorClimateAlerts={ongoingIndoorClimateAlerts}
      />
      <HumidityReaction
        sensorValues={sensorValues}
        chargeStatus={chargeStatus}
        indoorClimateMonitoring={indoorClimateMonitoring}
        ongoingIndoorClimateAlerts={ongoingIndoorClimateAlerts}
      />
    </Box>
  )
}

function TemperatureReaction({
  sensorValues,
  unit,
  chargeStatus,
  indoorClimateMonitoring,
  ongoingIndoorClimateAlerts,
}: {
  sensorValues: TDevice["latest_sensor_values"]
  unit: TTemperatureUnit
  chargeStatus?: TChargeStatus
  indoorClimateMonitoring?: TIndoorClimateMonitoring
  ongoingIndoorClimateAlerts: NonNullable<
    TDevice["ongoing_indoor_climate_alerts"]
  >
}) {
  const latestTemperatureValue = sensorValues?.temperature?.value

  const temperatureToggleEnabled = indoorClimateMonitoring?.temperature_enabled

  const isAlmostFreezing =
    ongoingIndoorClimateAlerts.includes("almost_freezing")

  const isHighTemperatureEvent =
    ongoingIndoorClimateAlerts.includes("temperature_high")
  const isLowTemperatureEvent =
    ongoingIndoorClimateAlerts.includes("temperature_low")

  const formattedTemperatureReading =
    chargeStatus === "connected_charging" ? (
      "-"
    ) : (
      <LocalizedTemperature value={latestTemperatureValue} />
    )

  const convertedTemperatureValue =
    latestTemperatureValue === undefined
      ? null
      : Number(convertTemperatureValue(latestTemperatureValue, unit))

  if (!temperatureToggleEnabled) {
    return <div></div>
  }

  if (convertedTemperatureValue !== null && isAlmostFreezing) {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={formattedTemperatureReading}
        icon={AlmostFreezingIcon}
      />
    )
  }

  if (convertedTemperatureValue !== null && isHighTemperatureEvent) {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={formattedTemperatureReading}
        icon={HighTemperatureIcon}
      />
    )
  }

  if (convertedTemperatureValue !== null && isLowTemperatureEvent) {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={formattedTemperatureReading}
        icon={LowTemperatureIcon}
      />
    )
  }

  return (
    <StatusDisplay
      color={colorsV2.systemGoodDark}
      reading={formattedTemperatureReading}
      icon={NormalTemperatureIcon}
    />
  )
}

function HumidityReaction({
  sensorValues,
  chargeStatus,
  indoorClimateMonitoring,
  ongoingIndoorClimateAlerts,
}: {
  sensorValues: TDevice["latest_sensor_values"]
  chargeStatus?: TChargeStatus
  indoorClimateMonitoring?: TIndoorClimateMonitoring
  ongoingIndoorClimateAlerts: NonNullable<
    TDevice["ongoing_indoor_climate_alerts"]
  >
}) {
  const latestHumidityValue = getSensorValue(sensorValues?.humidity)

  const humidityToggleEnabled = indoorClimateMonitoring?.humidity_enabled

  const isHighHumidityEvent =
    ongoingIndoorClimateAlerts.includes("humidity_high")
  const isLowHumidityEvent = ongoingIndoorClimateAlerts.includes("humidity_low")

  const formattedHumidityReading =
    chargeStatus === "connected_charging" ? "-" : `${latestHumidityValue} %`

  const convertedHumidityValue = !!sensorValues?.humidity?.value
    ? Number(latestHumidityValue)
    : null

  if (!humidityToggleEnabled) {
    return <div></div>
  }

  if (convertedHumidityValue !== null && isHighHumidityEvent) {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={formattedHumidityReading}
        icon={HighHumidityIcon}
      />
    )
  }

  if (convertedHumidityValue !== null && isLowHumidityEvent) {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={formattedHumidityReading}
        icon={LowHumidityIcon}
      />
    )
  }

  return (
    <StatusDisplay
      color={colorsV2.systemGoodDark}
      reading={formattedHumidityReading}
      icon={NormalHumidityIcon}
    />
  )
}

function StatusDisplay({
  color,
  reading,
  icon,
}: {
  color: string
  reading: React.ReactNode
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
}) {
  const StatusIcon = icon

  return (
    <MText variant="subtitleS">
      <ReactionBox $color={color}>
        <StatusIcon color={color} width={24} height={24} />
        {reading}
      </ReactionBox>
    </MText>
  )
}

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing.M};
`

const ReactionBox = styled.div<{ $color: string }>`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: ${spacing.XS2};
  color: ${({ $color }) => $color};
`
